var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading ? _c("Loading") : _vm._e(),
      _vm.error && !_vm.loading
        ? _c("Error", [_vm._v(" " + _vm._s(_vm.error) + " ")])
        : _vm._e(),
      _vm.items && _vm.items.length
        ? _c("b-table", {
            attrs: {
              items: _vm.items,
              fields: _vm.fields,
              striped: "",
              dark: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(created)",
                  fn: function(row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("relativeDate")(row.item.created)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(actions)",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "sm",
                                variant: "warning",
                                to: row.item.route
                              }
                            },
                            [_vm._v(" Load Report ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              1190870463
            )
          })
        : _vm._e(),
      !_vm.loading && !_vm.error && !(_vm.items && _vm.items.length)
        ? _c("div", { staticClass: "text-center text-muted mt-5 mb-5" }, [
            _vm._v(" No reports available. ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }